/* styles.css */

body {
  background-color: #282a36;
  color: #f8f8f2;
}

button {
  background-color: #44475a;
  color: #f8f8f2;  
}

h1 {
  color: #6272a4;
}
